<template>
  <v-card dark class="mx-auto backBlueDarken backBlueLight--text" :loading="loading" outlined elevation="3">
<!--     <v-card-title class="blue-grey darken-2 pa-5">
      INFO
      <v-spacer />
      <v-btn  fab dark small color="secondary" @click.stop="getInfo()">
        <v-icon dark>
          mdi-reload
        </v-icon>
      </v-btn>
    </v-card-title> -->

    <v-app-bar dark class="blue-grey darken-2">
      <v-toolbar-title>INFO</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="secondary" fab small dark @click.stop="getInfo">
        <v-icon dark>mdi-reload</v-icon>
      </v-btn>
    </v-app-bar>

    <v-card-text class="blue-grey">

              <v-form  ref="form">
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      type="number"
                      color="accent"
                      v-model="info.code"
                      label="Code"
                      dense
                      filled />
                    <v-text-field
                      color="accent"
                      v-model="info.boatname"
                      label="Boat Name"
                      dense
                      filled />
                    <v-text-field
                      color="accent"
                      v-model="info.phone"
                      label="Phone"
                      dense
                      filled />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      color="accent"
                      v-model="info.firstname"
                      label="First Name"
                      dense
                      filled />
                    <v-text-field
                      color="accent"
                      v-model="info.lastname"
                      label="Last Name"
                      dense
                      filled />
                    <v-text-field
                      color="accent"
                      v-model="info.email"
                      label="Email"
                      dense
                      filled />

                    <!-- <v-switch
                      inset
                      label="LOG"
                      color="success"
                      v-model="info.log"
                    /> -->

                  </v-col>
                </v-row>
              </v-form>

    </v-card-text>
    <v-card-actions class="blue-grey darken-2">
      <v-btn
        :loading=saving
        color="primary"
        @click="updateBoat"
      >
        Update
      </v-btn>
      <v-spacer />
      <v-alert dense type="success" v-if="successful"> Update successful</v-alert>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapState, mapActions, mapGetters} from 'vuex';
  import { dbFb } from '@/main';
  export default {
    name: 'showInfo',
    data: () => ({
      info: {
        boatname: '',
        code: '0',
        dealer: '',
        email: '',
        firstname: '',
        lastname: '',
        phone: '',
        log: false
      },
      loading: false,
      successful: false,
      saving: false,
    }),
    mounted(){
      this.getInfo()
    },
    methods: {
       getInfo(){
        let me = this
        // me.loading = true
        let _imei = this.$route.params.imei
        dbFb.ref(`/${_imei}/info`).once('value')
        .then( function(snap){
          me.info = snap.val();
          // me.info.log  = me.info.log == null ? false : me.info.log
          me.info.code = String(me.info.code)
          me.loading = false
				})


/* 				 dbFb.ref(`/${_imei}/`).on("value", (snapshot) => {
            snapshot.forEach((doc) =>{
								me.info = doc.val();
								me.info.log  = me.info.log == null ? false : me.info.log
          			me.info.code = me.info.code ? String(me.info.code) : 0
            });
          }); */

      },
      updateBoat(){
        let _imei = this.$route.params.imei
        let me = this
        me.saving = true;
				me.info.code = parseInt(me.info.code);

				me.info.boatname = me.info.boatname ? me.info.boatname : 'NOT SET'
				me.info.code = me.info.code ? me.info.code : 0
				me.info.email = me.info.email ? me.info.email : 'NOT SET'
				me.info.firstname = me.info.firstname ? me.info.firstname : 'NOT SET'
				me.info.lastname = me.info.lastname ? me.info.lastname : 'NOT SET'
				me.info.phone = me.info.phone ? me.info.phone : 'NOT SET'

        dbFb.ref(`/${_imei}/info`).update({
          boatname: me.info.boatname,
          code: me.info.code,
          email: me.info.email,
          firstname: me.info.firstname,
          lastname: me.info.lastname,
          phone: me.info.phone,
          // log: me.info.log
        }).then( docRef => {
            me.info.code = String(me.info.code)
            me.successful = true
            me.dateSelected = false
            me.saving = false
            setTimeout(() => me.successful = false, 3000);
          })
          .catch(function(error) {
            me.saving = false
            console.log("Error :", error);
          });

      },

    },
  }
</script>

<style lang="scss" scoped>

</style>